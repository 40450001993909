<template>
  <div class="account-info-warp">
    <div class="category-item">
      <div class="title">登录账号</div>
      <div class="account-box">
        <div class="main-info">
          <img class="status-icon" src="/images/icon-iphone.svg" alt="">
          <p class="text">
            手机号
          </p>
        </div>
        <div class="sub-info">
          <div class="msg-text" v-if="user_info.account">
            {{user_info.account | mobileMask}}
          </div>
          <div class="msg-text" v-else>
            绑定手机号，登录更方便
          </div>
          <div v-if="env != 'private'" class="func-btn" @click="goBindPhone">
            <span>{{user_info.account ? '更改' : '立即绑定'}}</span>
            <img src="/images/icon-arrowline-right.svg" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="account-box">
        <div class="main-info">
          <img class="status-icon" src="/images/icon-password.svg" alt="">
          <p class="text">
            登录密码
          </p>
        </div>
        <div class="sub-info">
          <div class="msg-text">
            {{user_info.password == 1 ? '已设置密码，可以使用手机号+密码登录' : '还未设置密码'}}
          </div>
          <div v-if="env != 'private'" class="func-btn" @click="setPwdShow">
            <span>
            {{user_info.password == 1 ? '重置密码' : '设置'}}
            </span>
            <img src="/images/icon-arrowline-right.svg" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
    <div class="category-item" v-if="from != 'yg' && env != 'private'">
      <div class="title">绑定的第三方账号</div>
      <div class="account-box">
        <div class="main-info">
          <img class="status-icon" src="/images/icon-wechat.svg" alt="">
          <p class="text">
            微信（{{user_info.wechat_nickname || '未绑定'}}）
          </p>
        </div>
        <div class="sub-info">
          <div class="msg-text">
            {{user_info.wechat_nickname ? '可使用微信授权或扫码快捷登录' : '绑定后可使用微信快捷登录'}}
          </div>
          <div class="func-btn" @click="startWxBindOrUnbind">
            <span>{{user_info.wechat_nickname ? '解除绑定' : '立即绑定'}}</span>
            <img src="/images/icon-arrowline-right.svg" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
    <a-modal title="验证手机号"
      centered
      width="560px"
      v-model="pwd_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="确认重置"
      @ok="pwdConfirm"
      >
        <a-form-model ref="form" :model="form" :rules="pwd_rules">
          <a-form-model-item label="" prop="my_account">
            <a-input v-model="form.my_account"  :disabled="true" placeholder="请输入手机号" @change="mobileChange"/>
          </a-form-model-item>
          <a-form-model-item class="code-box" label="" prop="code">
            <a-input class="code-input" v-model="form.code" placeholder="短信验证码" @change="smsChange">
              <div slot="addonAfter" class="code-btn" :class="{'active': can_send_code === true}" @click="sendCode('modify_password')">{{send_code_text}}</div>
            </a-input>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    <a-modal title="重置密码"
      centered
      width="560px"
      v-model="pwd_confirm_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="下一步"
      @ok="pwdConfirm2"
      >
        <a-form-model ref="form" :model="form" :rules="pwd_rules">
          <a-input v-show="false" />
          <a-form-model-item label="" prop="password">
            <a-input id="noname" type="password" v-model="form.password" autocomplete="new-password"  placeholder="输入密码，建议8-20位英文或数字" />
          </a-form-model-item>
          <a-form-model-item label="" prop="confirm_password">
            <a-input id="noname2" type="password" v-model="form.confirm_password" autocomplete="new-password2" placeholder="两次输入密码" />
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    <a-modal title="请先绑定手机号"
      centered
      width="560px"
      v-model="pwd_fail_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="稍后再说"
      okText="立即绑定"
      @ok="() => {pwd_fail_show =false;goBindPhone()}"
      >
      <div class="modal-text">设置密码后，可以使用手机号+密码进行登录，请先绑定手机号后，再设置密码</div>
    </a-modal>
    <a-modal :title="`绑定${change_bind ? '新' : ''}手机号`"
      centered
      width="560px"
      v-model="bind_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="下一步"
      @ok="goBindPhoneVertify"
      >
        <div class="modal-text" style="margin-bottom: 16px">请在下方输入你要绑定的手机号</div>
        <a-form-model ref="bind_form" :model="form" :rules="bind_rules">
          <a-form-model-item label="" prop="account">
            <a-input v-model="form.account" placeholder="请输入手机号码" @change="mobileChange"/>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    <a-modal title="绑定失败"
      centered
      width="560px"
      v-model="bind_fail_show"
      :maskClosable="false"
      :keyboard="false"
      >
      <template slot="footer">
        <a-button type="primary" @click="bind_fail_show = false">知道了</a-button>
      </template>
      <div class="modal-text">你输入的手机号已注册账号「{{bind_info.nickname}}」，且绑定了微信号，请使用该手机号直接登录。</div>
      <!-- <div class="modal-info-text">仍想绑定手机号「{{form.account}}」至当前账号，请联系客服，客服微信号：BPYD2019</div> -->
      <div class="modal-text" style="color: #17181a;">当前暂不支持账号合并，有疑问请<span style="color: #2c65ff;" @click="showHelpModal">联系客服</span></div>
    </a-modal>
    <a-modal title="手机号已被注册"
      centered
      width="560px"
      v-model="bind_confirm_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="上一步"
      okText="下一步"
      @ok="sureBindPhone"
      @cancel="returnBindPhone"
      >
      <div class="modal-text">你输入的手机号「{{form.account}}」已注册账号「{{bind_info.nickname}}」，你是否要解除该手机号与「{{bind_info.nickname}}」的绑定，并将该手机号绑定至当前账号。</div>
      <div class="modal-error-text" style="margin-bottom: 16px">解除绑定后，你将无法使用该手机号登录「{{bind_info.nickname}}」，该账号下已产生的所有数据和记录也将遗失且不可恢复，请慎重操作，请在下方输入“确认更换手机号”确保你已知悉操作的结果。</div>
      <a-form-model ref="sure_form" :model="sure_form" :rules="sure_rules">
        <a-form-model-item label="" prop="text">
          <a-input v-model="sure_form.text" placeholder="确认更换手机号"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="绑定失败"
      centered
      width="560px"
      v-model="bind_confirm_show_new"
      :maskClosable="false"
      :keyboard="false"
      :okText="bindPhoneDetail.status === 2 ? '合并账号' : '知道了'"
      @ok="handleBindPhoneConfirmNew"
      >
      <div v-if="bindPhoneDetail.status === 2" class="modal-text">你输入的手机号已注册账号「{{username}}」。</div>
      <div v-if="bindPhoneDetail.status === 2" class="modal-text" style="color: #17181a;">若需继续绑定，可将当前登录的账号「{{user_info.nickname}}」与此微信关联的账号「{{ bind_wx_fail_info.nickname }}」进行合并，请确认是否进行账号合并？</div>

      <div v-if="bindPhoneDetail.status === 3" class="modal-text">你输入的手机号已注册账号「{{username}}」，并且已绑定微信号。请使用该手机号/微信号直接登录</div>
      <div v-if="bindPhoneDetail.status === 3" class="modal-text" style="color: #17181a;">当前暂不支持绑定，有疑问请<span style="color: #2c65ff;" @click="showHelpModal">联系客服</span></div>
    </a-modal>
    <a-modal title="验证手机号"
      centered
      width="560px"
      v-model="bind_vertify_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="上一步"
      :okText="bindPhoneDetail.status === 2 ? '合并账号' : '立即绑定'"
      @ok="doBindPhone"
      @cancel="returnBindPhone"
      >
        <div class="modal-text" style="margin-bottom: 16px">为了你的账号安全，请点击下方的发送验证码按钮，并在输入框中输入手机号收到的验证码</div>
        <a-form-model ref="vertify_form" :model="form" :rules="bind_rules">
          <a-form-model-item label="" prop="account">
            <a-input v-model="form.account"  :disabled="true" placeholder="请输入手机号" @change="mobileChange"/>
          </a-form-model-item>
          <a-form-model-item class="code-box" label="" prop="code">
            <a-input class="code-input" v-model="form.code" placeholder="短信验证码" @change="smsChange">
              <div slot="addonAfter" class="code-btn" :class="{'active': vertify_form_code.can_send_code === true}" @click="sendCode2('modify_account')">{{vertify_form_code.send_code_text}}</div>
            </a-input>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    <a-modal title="验证手机号"
      centered
      v-if="bind_my_vertify_show"
      width="560px"
      v-model="bind_my_vertify_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="下一步"
      @ok="doVertifyMyPhone"
      @cancel="bind_my_vertify_show = false"
      >
        <div class="modal-text" style="margin-bottom: 16px">为了你的账号安全，请先验证当前绑定的手机号</div>
        <a-form-model ref="my_vertify_form" :model="form" :rules="change_bind_rules">
          <a-form-model-item label="" prop="my_account">
            <a-input v-model="form.my_account"  :disabled="true" placeholder="请输入手机号" @change="mobileChange"/>
          </a-form-model-item>
          <a-form-model-item class="code-box" label="" prop="code">
            <a-input class="code-input" v-model="form.code" placeholder="短信验证码" @change="smsChange">
              <div slot="addonAfter" class="code-btn" :class="{'active': my_vertify_form_code.can_send_code === true}" @click="sendCode3('modify_account')">{{my_vertify_form_code.send_code_text}}</div>
            </a-input>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
    <!-- 绑定微信号提示 - 1 -->
    <a-modal title="绑定微信账号"
      centered
      width="560px"
      v-model="bind_wx_fail_show"
      :maskClosable="false"
      :keyboard="false"
      >
        <template slot="footer">
          <a-button v-if="bind_wx_fail_info.account" type="primary" @click="bind_wx_fail_show = false">我知道了</a-button>
          <a-button v-if="!bind_wx_fail_info.account" type="default" @click="bind_wx_fail_show = false">取消</a-button>
          <a-button v-if="!bind_wx_fail_info.account" type="primary" @click="goToMerge">账号合并</a-button>
        </template>
        <template v-if="bind_wx_fail_info.account">
          <div class="modal-text">此微信号不可绑定，原因为：</div>
          <div class="modal-info-text">此微信账号已关联博普智库平台的另一个账号： 「{{ bind_wx_fail_info.nickname }}」，该账号关联的手机号为：「{{ bind_wx_fail_info.account }}」</div>
        </template>
        <template v-else>
          <div class="modal-text">此微信账号已关联博普智库平台的另一个账号：「{{bind_wx_fail_info.nickname}}」</div>
          <div class="modal-info-text">若需继续绑定，可将当前登录的账号「{{user_info.nickname}}」与此微信关联的账号「{{ bind_wx_fail_info.nickname }}」进行合并，请确认是否进行账号合并？</div>
        </template>
    </a-modal>
    <!-- 绑定微信号提示 - 2 -->
    <a-modal
      title="绑定微信账号"
      centered
      width="560px"
      v-model="show_force_bind_modal"
      :maskClosable="false"
      :keyboard="false"
    >
      <div class="modal-text">此微信账号已关联博普智库平台的另一个账号：「{{bind_wx_fail_info.nickname}}」</div>
      <div class="modal-info-text">是否确认将原有账号「{{bind_wx_fail_info.nickname}}」注销并继续完成绑定？注销后该账号下所有余额、数据将不再保留，请谨慎操作。<br/><a target="_blank" href="https://www.bopuyun.com/article/110308">《账号绑定说明》</a></div>
      <p class="bindConfirmTitle">绑定确认</p>
      <a-form-model ref="forceBindFormRef"
        :model="forceBindForm"
        :rules="forceBindFormRule"
      >
        <a-form-model-item label="手机号" prop="account">
          <a-input v-model="forceBindForm.account" placeholder="请输入你当前账号的手机号进行确认"/>
        </a-form-model-item>
        <a-form-model-item label="注销原账号" prop="confirmHandle">
          <a-radio-group v-model="forceBindForm.confirmHandle">
            <a-radio :value="1">确认注销</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="确认已知晓《微信账号绑定说明》" prop="confirmInfo">
          <a-radio-group v-model="forceBindForm.confirmInfo">
            <a-radio :value="1">是，我已知晓</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button type="default" @click="show_force_bind_modal = false">取消</a-button>
        <a-button type="primary" @click="doBindWechatForce">确定绑定</a-button>
      </template>
    </a-modal>
    <a-modal title="你确认要解除微信绑定吗"
      centered
      width="560px"
      v-model="unbind_wx_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="下一步"
      @ok="sureUnbindWx"
      >
      <div class="modal-text" style="margin-bottom: 16px">是否解除微信号「{{user_info.wechat_nickname}}」与当前账号的绑定，解除绑定后，将不能使用此微信登录当前账号，请在下方输入“解除绑定”以确认操作</div>
      <a-form-model ref="unbindwx_sure_form" :model="sure_form" :rules="sure_rules2">
        <a-form-model-item label="" prop="text">
          <a-input v-model="sure_form.text" placeholder="解除绑定"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="验证手机号"
      centered
      width="560px"
      v-model="unbind_wx_vertify_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="下一步"
      @ok="doUnbindWx"
      >
      <div class="modal-text" style="margin-bottom: 16px">为了你的账号安全，请点击下方的发送验证码按钮，并在输入框中输入手机号收到的验证码</div>
      <a-form-model ref="wx_vertify_form" :model="form" :rules="unbind_rules">
        <a-form-model-item label="" prop="my_account">
          <a-input v-model="form.my_account"  :disabled="true" placeholder="请输入手机号" @change="mobileChange"/>
        </a-form-model-item>
        <a-form-model-item class="code-box" label="" prop="code">
          <a-input class="code-input" v-model="form.code" placeholder="短信验证码" @change="smsChange">
            <div slot="addonAfter" class="code-btn" :class="{'active': wx_vertify_form_code.can_send_code === true}" @click="sendCode4('modify_account')">{{wx_vertify_form_code.send_code_text}}</div>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="请先绑定手机号"
      centered
      width="560px"
      v-model="unbind_wx_fail_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="稍后再说"
      okText="立即绑定"
      @ok="() => {unbind_wx_fail_show =false;goBindPhone()}"
      >
      <div class="modal-text">为了确保解绑微信后能正常登录，请先绑定手机号之后，再解除微信绑定</div>
    </a-modal>
    <div class="adTempModal" v-if="helpModal">
      <div class="adtemp-modal">
        <img class="temp" src="https://bpy-store.oss-cn-hangzhou.aliyuncs.com/ppc/8e/8ee3d70d4f45d46f823a021da8f97dae/account_kefu.png">
        <div class="temp-text">扫码添加客服</div>
        <img class="close" @click="helpModal = false" src="https://bpy-store.oss-cn-hangzhou.aliyuncs.com/ppc/3e/3e7601cb8766aa6b9528a41254e37ff6/icon-close-fill%403x.png">
      </div>
    </div>
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
import { mapState } from 'vuex'
export default {
  name: 'accountInfo',
  created () {
    this.initPage()
  },
  data () {
    const validateSureText = async(rule, value, callback) => {
      if (value !== '确认更换手机号') {
        callback(new Error('请输入“确认更换手机号”'));
      } else {
        callback()
      }
    }
    const validateSureText2 = async(rule, value, callback) => {
      if (value !== '解除绑定') {
        callback(new Error('请输入“解除绑定”'));
      } else {
        callback()
      }
    }
    
    const validateMobile = async(rule, value, callback) => {
      this.mobile_check = false
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!new RegExp(phone_reg).test(value)){
        callback(new Error('请输入正确的11位手机号'))
      } else if (this.failure_code && this.failure_code == '新手机号不能与当前手机号相同') {
        callback(new Error('新手机号不能与当前手机号相同'))
      } else {
        this.mobile_check = true
        callback()
      }
    }
    const validatePwd = async(rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if(!Util.validatePwd(value)) {
        callback(new Error('密码为6~20位，数字、大写字母和小写字母两种以上组合'));
      } else {
        callback()
      }
    }

    const validatePwd2 = async(rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入重置密码'))
      } else if(value != this.form.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    
    const validateMsgVerification = (rule, value, callback) => {
      if (new RegExp(/^$| /).test(this.form.code)) {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 505) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    const validateMsgVerification2 = (rule, value, callback) => {
      if (new RegExp(/^$| /).test(this.form.code)) {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 505) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    const validateCurrentAccount = (rule, value, callback) => {
      if (!this.forceBindForm.account) {
        callback(new Error('请输入当前手机号'))
      } else if (this.user_info.account !== this.forceBindForm.account) {
        callback(new Error('手机号输入错误，请重新输入！'))
      } else {
        callback()
      }
    }
    return {
      helpModal: false,
      show_force_bind_modal: false,
      forceBindForm: {},
      forceBindFormRule: {
        account: [
          { required: true, message: '请输入当前手机号', trigger: 'change' },
          { validator: validateCurrentAccount, trigger: 'blur' }
        ],
        confirmHandle: [ {required: true, message: '请确认注销原账号', trigger: 'change' } ],
        confirmInfo: [ {required: true, message: '确认已知晓《微信账号绑定说明》', trigger: 'change' } ]
      },
      username: '',
      loading: false,
      bind_info: {},
      change_bind: false,
      code_count: '',
      vertify_form_code_count: '',
      my_vertify_form_code_count: '',
      wx_vertify_form_code_count: '',
      pwd_show: false,
      pwd_confirm_show: false,
      pwd_fail_show: false,
      bind_show: false,
      bind_my_vertify_show: false,
      bind_fail_show: false,
      bind_confirm_show: false,
      bind_confirm_show_new: false,
      bindPhoneDetail: {},
      bind_vertify_show: false,
      change_bind_show: false,
      change_bind_show2: false,
      bind_wx_fail_show: false,
      unbind_wx_show: false,
      unbind_wx_fail_show: false,
      unbind_wx_vertify_show: false,
      mobile_check: false,
      bind_wx_fail_text: '',
      bind_wx_fail_info: {},
      form: {
        my_account: '',
        account: '',
        code: '',
        password: '',
        confirm_password: ''
      },
      sure_form: {
        text: ''
      },
      pwd_rules: {
        my_account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification2, trigger: 'blur' }
        ],
        password: [
          { validator: validatePwd, trigger: 'blur' }
        ],
        confirm_password: [
          { validator: validatePwd2, trigger: 'blur' }
        ]
      },
      bind_rules: {
        account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification, trigger: 'blur' }
        ]
      },
      change_bind_rules: {
        my_account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification, trigger: 'blur' }
        ]
      },
      unbind_rules: {
        my_account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification, trigger: 'blur' }
        ]
      },
      sure_rules: {
        text: [
          { validator: validateSureText, trigger: 'blur' }
        ]
      },
      sure_rules2: {
        text: [
          { validator: validateSureText2, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      env: state => state.env,
    }),
    can_send_code() {
      return !this.code_status && this.mobile_check
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}S`
      }
      return '获取验证码'
    },
    vertify_form_code() {
      let code_status = this.vertify_form_code_count > 0
      let send_code_text = code_status ? `${this.vertify_form_code_count}S` : '获取验证码'
      return {
        can_send_code: !code_status && this.mobile_check,
        code_status: code_status,
        send_code_text: send_code_text
      }
    },
    my_vertify_form_code() {
      let code_status = this.my_vertify_form_code_count > 0
      let send_code_text = code_status ? `${this.my_vertify_form_code_count}S` : '获取验证码'
      return {
        can_send_code: !code_status && this.mobile_check,
        code_status: code_status,
        send_code_text: send_code_text
      }
    },
    wx_vertify_form_code() {
      let code_status = this.wx_vertify_form_code_count > 0
      let send_code_text = code_status ? `${this.wx_vertify_form_code_count}S` : '获取验证码'
      return {
        can_send_code: !code_status && this.mobile_check,
        code_status: code_status,
        send_code_text: send_code_text
      }
    }
  },
  methods: {
    handleBindPhoneConfirmNew () {
      this.bind_confirm_show_new = false
      if (this.bindPhoneDetail.status === 2) {
        location.href = `${this.zhiku_url}/merge/account?targetUid=${Util.numberEncode(this.bindPhoneDetail.uid)}`
      }
    },
    goToMerge () {
      location.href = `${this.zhiku_url}/merge/account?targetUid=${Util.numberEncode(this.bind_wx_fail_info.uid)}`
    },
    showForceBindWechat () {
      this.bind_wx_fail_show = false
      this.show_force_bind_modal = true
      this.forceBindForm = {
        account: '',
        confirmHandle: undefined,
        confirmInfo: undefined
      }
    },
    async initPage() {
      if (this.$route.query.key) {
        let res = await AuthService.getWechatCallBackInfo({...this.$route.query})
        if (res) {
          this.wechat_data = res
          this.doBindWechat()
        }
      }
    },
    mobileChange() {
      this.failure_code = false
    },
    smsChange() {
      this.failure_code = false
    },
    async sendCode(key) {
      if(!this.can_send_code) return
      let param = {
        account: this.form.my_account,
        sms_type: key || 'modify_password'
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '换绑手机号',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    async sendCode2(key) {
      if(!this.vertify_form_code.can_send_code) return
      let param = {
        account: this.form.account,
        sms_type: key
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '换绑手机号',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.vertify_form_code_count = 60
        let timer = setInterval(() => {
          this.vertify_form_code_count--
          if(this.vertify_form_code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    async sendCode3(key) {
      if(!this.my_vertify_form_code.can_send_code) return
      let param = {
        account: this.form.my_account,
        sms_type: key
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '换绑手机号',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.my_vertify_form_code_count = 60
        let timer = setInterval(() => {
          this.my_vertify_form_code_count--
          if(this.my_vertify_form_code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    async sendCode4(key) {
      if(!this.wx_vertify_form_code.can_send_code) return
      let param = {
        account: this.form.my_account,
        sms_type: key
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '换绑手机号',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.wx_vertify_form_code_count = 60
        let timer = setInterval(() => {
          this.wx_vertify_form_code_count--
          if(this.wx_vertify_form_code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    setPwdShow() {
      if (!this.user_info.account) {
        this.pwd_fail_show = true
      } else {
        this.pwd_show = true
        this.form = {
          my_account: this.user_info.account,
          code: '',
          password: '',
          confirm_password: ''
        }
        this.$nextTick(() => {
          this.$refs.form.validateField('my_account')
        })
      }
    },
    async pwdConfirm() {
      this.$refs.form.validate(async valid=> {
        if (valid) {
          this.form = { 
            ...this.form,
            password: '',
            confirm_password: ''
          }
          let param = {
            account: this.form.my_account,
            code: this.form.code
          }
          let res = await AuthService.verifySmsCode(param).catch( err => {
            this.failure_code = err.errCode
            this.$nextTick(() => {
              this.$refs.form.validateField('code')
            })
          })
          if (res) {
            this.form.token_key = res.token_key
            this.pwd_show = false
            this.pwd_confirm_show = true
          }
        }
      })
    },
    async pwdConfirm2() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let param = {
            account: this.form.my_account,
            token_key: this.form.token_key,
            password: this.form.password,
          }
          let res = await AuthService.modifyPassword(param)
          if (res) {
            this.$message.success('修改密码成功')
            this.pwd_confirm_show = false
            this.initUserInfo()
          }
        }
      })
    },
    // 绑定/修改绑定号点击
    goBindPhone() {
      //已绑定手机号
      this.vertify_form_code_count = ''
      this.my_vertify_form_code_count = ''
      if (this.user_info.account) {
        this.bind_my_vertify_show = true
        this.change_bind = true
        this.form = {
          my_account: this.user_info.account,
          account: '',
          code: '',
          password: '',
          confirm_password: ''
        }
        this.failure_code = false
        this.$nextTick(() => {
          try {
            this.$refs.my_vertify_form.resetFields()
            this.$refs.my_vertify_form.validateField('my_account')
          } catch {}
        })
      } else {
        //未绑定手机号
        this.bind_show = true
        this.change_bind = false
        this.form = {
          my_account: '',
          account: '',
          code: '',
          password: '',
          confirm_password: ''
        }
        this.failure_code = false
        this.$refs.bind_form.resetFields()
        // this.$nextTick(() => {
        //   try {
        //     this.$refs.bind_form.validateField('account')
        //   } catch {}
        // })
      }
    },
    // 绑定手机号 返回上一步
    returnBindPhone() {
      this.bind_show = true
      this.form = {
        my_account: this.form.my_account,
        account: this.form.account,
        code: '',
        password: '',
        confirm_password: ''
      }
    },
    doVertifyMyPhone() {
      this.$refs.my_vertify_form.validate(async (valid) => {
        if (valid) {
          let param = {
            account: this.form.my_account,
            code: this.form.code,
          }
          let res = await AuthService.verifySmsCode(param).catch(err => {
            this.failure_code = err.errCode
            this.$nextTick(() => {
              this.$refs.my_vertify_form.validate()
            })
          })
          if (res) {
            this.bind_my_vertify_show = false
            this.bind_show = true
            this.$nextTick(() => {
              this.$refs.bind_form.resetFields()
            })
          }
        }
      })
    },
    // 校验新手机状态
    async goBindPhoneVertify() {
      if (this.change_bind && this.form.account === this.form.my_account) {
        this.failure_code = '新手机号不能与当前手机号相同'
        this.$nextTick(() => {
          this.$refs.bind_form.validateField('account')
        })
        return
      } 
      this.$refs.bind_form.validate(async (valid) => {
        if (valid) {
          let param = {
            account: this.form.account,
            return_status: 1,
            NO_Loading: false
          }
          let res = await AuthService.checkBindStatusCommon(param)
          if (res) {
            this.bind_info = res
            this.bind_show = false
            this.sure_form.text = ''
            // if (res.status == 3) {
            //   this.form.code = ''
            //   this.bind_fail_show = true
            //   return
            // }
            // if (res.status == 2) {
            //   this.form.code = ''
            //   this.sure_form.text = ''
            //   this.bind_confirm_show = true
            //   return
            // }
            if (res.status == 1) {
              this.form.code = ''
              this.bind_vertify_show = true
              this.bindPhoneDetail = {}
            } else {
              this.bindPhoneDetail = res
              if (res.status === 3) {
                this.username = res.nickname
                this.bind_confirm_show_new = true
              }
              if (res.status === 2) {
                this.form.code = ''
                this.bind_vertify_show = true
              }
            }
          } else {
            this.bind_confirm_show_new = true
          }
        }
      })
    },
    showHelpModal () {
      this.helpModal = true
    },
    goAccountMerge () {
      this.$router.push({
        path: '/bpy_account/change',
        query: this.$route.query
      })
    },
    sureBindPhone() {
      this.$refs.sure_form.validate((valid) => {
        if (valid) {
          this.bind_confirm_show = false
          this.bind_vertify_show = true
        }
      })
    },
    // 绑定手机号执行绑定
    async doBindPhone() {
      this.$refs.vertify_form.validate(async valid => {
        if (valid) {
          if (this.bindPhoneDetail.status === 2) {
            // 验证验证码
            const res = await AuthService.verifySmsCode({
              account: this.form.account,
              sms_type: 'modify_account',
              code: this.form.code,
            }).catch( err => {
              this.$message.error(err.errMsg || '验证失败')
            })
            if (res) {
              location.href = `${this.zhiku_url}/merge/account?targetUid=${Util.numberEncode(this.bindPhoneDetail.uid)}`
            }
          } else {
            let param ={
              code: this.form.code,
              account: this.form.account
            }
            let res = await AuthService.bpyBindPhone(param)
            if (res) {
              this.$message.success('绑定手机号成功')
              this.initUserInfo()
              this.bind_vertify_show = false
            }
          }
        }
      })
    },
    // 确认解绑微信
    sureUnbindWx() {
      this.$refs.unbindwx_sure_form.validate((valid) => {
        if (valid) {
          this.form.my_account = this.user_info.account
          this.unbind_wx_show = false
          this.unbind_wx_vertify_show = true
          this.$nextTick(() => {
            this.$refs.wx_vertify_form.validateField('my_account')
          })
        }
      })
    },
    //解绑微信
    async doUnbindWx() {
      let res = await AuthService.bpyCheckAccountIsBindPhone()
      if (res) {
        if (res.status == 1) { //已经绑定手机允许解绑
          this.$refs.wx_vertify_form.validate(async valid => {
            if (valid) {
              let param = {
                code: this.form.code
              }
              let res = await AuthService.bpyUnbindWechat(param)
              if (res) {
                this.$message.success('解除绑定成功')
                this.initUserInfo()
                this.unbind_wx_vertify_show = false
              }
            }
          })
        } else { //未绑定手机
          
          // this.unbind_wx_fail_show = true
        }
      }
    },
    async startWxBindOrUnbind() {
      this.wx_vertify_form_code_count = ''
      this.form = {
        my_account: this.form.my_account,
        account: '',
        code: '',
        password: '',
        confirm_password: ''
      }
      if (this.user_info.wechat_nickname) { //解绑
        let res = await AuthService.bpyCheckAccountIsBindPhone()
        if (res.status == 1) {
          this.sure_form.text = ''
          this.unbind_wx_show = true
          this.$refs.unbindwx_sure_form.resetFields()
        } else {
          this.unbind_wx_fail_show = true
        }
      } else {
        let redirect_uri = encodeURIComponent(`${location.origin}/bpy_accountInfo?key=${new Date().getTime()}`)
        location.href = `/getAuthUrl?redirectURI=${redirect_uri}`
      }
    },
    async doBindWechat() {
      let res = await AuthService.bpyBindWechat({user_info: JSON.stringify(this.wechat_data), use_user_info: 1}).catch(err => {
        if (err.errCode == 536) {
          this.bind_wx_fail_show = true
          this.bind_wx_fail_info = JSON.parse(err.data)
        } else {
          this.$message.error('操作失败')
        }
      })
      if (res) {
        this.$message.success('绑定微信成功')
        this.initUserInfo()
      }
    },
    // 强制绑定
    async doBindWechatForce () {
      this.$refs.forceBindFormRef.validate(async valid=> {
        if (valid) {
          let res = await AuthService.bpyBindWechat({
            user_info: JSON.stringify(this.wechat_data),
            use_user_info: 1,
            force_bind_wechat: 1
          }).catch(err => {
            if (err.errCode == 536) {
              this.bind_wx_fail_show = true
              this.bind_wx_fail_info = err.data
            } else {
              this.$message.error('操作失败')
            }
          })
          if (res) {
            this.$message.success('绑定微信成功')
            this.initUserInfo()
            this.show_force_bind_modal = false
          }
        }
      })
      
    }
  }
}
</script>

<style lang="less">
.bindConfirmTitle {
  font-size: 16px;
  color: #17181a;
  font-weight: 600;
  margin-top: 10px;
}
.adTempModal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adtemp-modal {
  padding: 24px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-radius: 6px;
  flex-direction: column;
  background-color: #fff;
}
.temp {
  width: 200px;
  height: 200px;
  padding: 9.1px;
  border-radius: 9.1px;
  border: 1px solid #eff1f4;
  background-color: #fafafa;
}
.temp-text {
  font-size: 14px;
  color: #37383a;
  margin-top: 10px;
}
.close {
  width: 29px;
  height: 29px;
  bottom: -58px;
  margin-top: 24px;
  position: absolute;
}
</style>

